import "./Titulos.css";

const Titulos = ({props})=> {
    const classes = `titulo segundo ${props.color}`
    return(
        <div className="titulos" id={props.id}>            
            <h1 className={classes}>{props.text}</h1>
        </div>
    )
}

export default Titulos;