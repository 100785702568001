import "./Pergunta.css"
import { useState, useEffect } from "react"
import Arrow from "../../assets/Shapes/BlackArrow.png"

const Pergunta = (props) => {
    const [open, setOpen] = useState(false)
    const [firstRender, setFirstRender] = useState(true);

    const handleClick = () => {
        setOpen(!open)
    }

    useEffect(() => {
        if(open) {
            const gap = document.querySelectorAll(".gapOpen");
            for(let i = 0; i < gap.length; i++) {
                gap[i].classList.remove("gapClose");
            }
            setFirstRender(false);
        } else{
            if(!firstRender) {
                const gap = document.querySelectorAll(".gapCloseEnd");
                for(let i = 0; i < gap.length; i++) {
                    gap[i].addEventListener("animationend", () => {
                        gap[i].classList.add("gapClose");
                    });
                }
            }
        }
    }, [open]);

    return(
        <div className="pergunta">
            <div className="pergunta-sempre" onClick={handleClick}>
                <h3>{props.props.pergunta}</h3>
                <img src={Arrow} alt="Seta para baixo" className={(open ? 'arrowOpen' : 'arrowClose')}/>
            </div>
            <p className={(open ? 'gapOpen' : (!firstRender ? 'gapCloseEnd' : 'gapClose'))}>{props.props.resposta}</p>
        </div>
    )
}

export default Pergunta;