import "./Horario.css";
import { useState, useEffect } from "react";
import SlotHorario from "../SlotHorario";
import RepeatedTitle from "../RepeatedTitle";

const Horario = () => {
    const [day, setDay] = useState("0");
    const [horario, setHorario] = useState([]);

    //on page load set day to 0
    useEffect(() => {
        setDay("0");
        //select the first day and give it the class "selected"
        document.getElementsByClassName("dia")[0].classList.add("selected");
    },[]);

    const changeDay = (event) => {
        //Set the day to the value of the button
        setDay(event.currentTarget.value);
        //remove the class "selected" from all buttons
        let buttons = document.getElementsByClassName("dia");
        for(let i = 0; i < buttons.length; i++){
            buttons[i].classList.remove("selected");
        }
        //add the class "selected" to the button that was clicked
        event.currentTarget.classList.add("selected");
    }

    //get day info from horario.json
    useEffect(() => {
        const getDayInfo = () => {
            let horario = require("../../assets/horario.json");
            setHorario(horario[day]);
        }
        getDayInfo();
    },[day]);
    
    return(
        //only load the page if horaio is not empty
        <div className="section horario" id="horario">
            <RepeatedTitle text="PROGRAMA" />
            <div className="container">
                <div className="dias">
                    <button className="dia" value="0" onClick={changeDay}>
                        <h1>2</h1>
                        <h2>TERÇA-FEIRA</h2>
                    </button>
                    <button className="dia" value="1" onClick={changeDay}>
                        <h1>3</h1>
                        <h2>QUARTA-FEIRA</h2>
                    </button>
                    <button className="dia" value="2" onClick={changeDay}>
                        <h1>4</h1>
                        <h2>QUINTA-FEIRA</h2>
                    </button>
                </div>
                <div className="horario-flex">
                    {horario !== [] &&
                    horario.map((slot) => {
                        return <SlotHorario props={slot} key={slot}/>
                    })}
                </div>
            </div>
        </div>
    
    )
}

export default Horario;
