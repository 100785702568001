import "./SobreNos.css"
import Titulos from "../Titulos";
import Boneco from "../../assets/sobre-boneco-img.svg"
import IMG1 from "../../assets/IMG1.svg"
import IMG2 from "../../assets/IMG2.svg"

const SobreNos = () => {

    return(
        <div className="section sobrenos" id="sobrenos">
            <a href="https://docs.google.com/forms/d/1rS6W6URMvBV-WP5HN7U4EvSCQzxtcg7bxd5-aJnzWVk/viewform?edit_requested=true" target="blank">
                <button className="inscricao">
                    <h1 className="button-text"> INSCREVE-TE AQUI </h1>
                    <div className="button-background"/>
                </button>
            </a>
            <Titulos props={{text:"PORQUÊ O JEK ACADEMY?", color:"black"}} />
            <div className="porques">
            <div className="porque">
                    <h2 className="porque-titulo">SEMPRE AMBICIOSOS</h2>
                    <p className="porque-texto">O ponto de partida para uma carreira de sucesso! Quem por cá passa garante </p>
                </div>
                <div className="porque">
                    <h2 className="porque-titulo">INSPIRA-TE</h2>
                    <p className="porque-texto">Vais estar em contacto com pessoas que, como tu, ambicionam fazer mais e melhor</p>
                </div>
                <div className="porque">
                    <h2 className="porque-titulo">NOVAS TECNOLOGIAS</h2>
                    <p className="porque-texto">Na jeKnowledge adotamos sempre as melhores práticas tecnológicas</p>
                </div>
                <div className="porque">
                    <h2 className="porque-titulo">CONTACTOS</h2>
                    <p className="porque-texto">Constrói pontes sólidas entre o Ensino Secundário e o Ensino Superior</p>
                </div>
                <div className="porque">
                    <h2 className="porque-titulo">FAZ NOVOS AMIGOS</h2>
                    <p className="porque-texto">É verão! Já acabaram os exames, está na altura de conhecer novas pessoas e aprender novas coisas</p>
                </div>
                <div className="porque">
                    <h2 className="porque-titulo">APROVEITA!</h2>
                    <p className="porque-texto">Tiramos a tua próxima foto de perfil. Afinal de contas, há fotógrafos na equipa</p>
                </div>
            </div>

            <Titulos props={{text:"SOBRE O JEKNOWLEDGE ACADEMY", color:"black", id:"sobrenos"}} />
            <p className="paragrafo">O evento que dá aos alunos do Ensino Secundário a oportunidade de<br></br>terem o primeiro contacto com Informática, Eletrónica e Design.</p>
            <div className="sobrenos-imagens">
                <div className="sobrenos-box">
                    <img className="sobrenos-imagem" src={IMG2} alt="Jovens a assistir a um workshop de Processing"/>
                </div>
                <div className="sobrenos-box">
                    <img className="sobrenos-imagem" src={IMG1} alt="Jovens a visitar o departamento de Engenharia Mecânica"/>
                </div>
                
            </div>
            
       
            <img className="sobre-imagem" src={Boneco} alt="Imagem do boneco 2"/>
                
        </div>
    )
}

export default SobreNos;