import "./SlotHorario.css"

const SlotHorario = ({props}) => {
    return(
        <div className="horario-item">
            <h1 className="horario-item-time">{props.inicio}H — {props.fim}H</h1>
            <div className="horario-item-text">
                <h1>{props.titulo}</h1>
                <p>{props.descricao}</p>
            </div>
        </div>
    )
}

export default SlotHorario;