import React, { useState, useEffect, useRef } from 'react';
import './RepeatedTitle.css';


const RepeatedTitle = ({ text }) => {
    const [count, setCount] = useState(1);
    const titleRef = useRef(null);

    const updateTitleCount = () => {
        if (titleRef.current) {
            const titleWidth = titleRef.current.offsetWidth;
            const screenWidth = window.innerWidth;
            const titlesThatFit = Math.floor((screenWidth / titleWidth) / 2);
            setCount(titlesThatFit);
        }
    };

    useEffect(() => {
        updateTitleCount();
        window.addEventListener('resize', updateTitleCount);
        return () => {
            window.removeEventListener('resize', updateTitleCount);
        };
    }, []);

    const titles = [];
    for (let i = 0; i < count; i++) {
        titles.push(<h1 key={`title-${i}`} className="repeated-title">{text}</h1>);
    }

    return (
        <div className="repeated-titles">
            {titles}
            <h1 ref={titleRef} className="repeated-title central">{text}</h1>
            {titles}
        </div>
    );
};

export default RepeatedTitle;