import "./Parceiros.css";
import UC from "../../assets/UC2.png";
import Titulos from "../Titulos";
import RepeatedTitle from "../RepeatedTitle";

const Parceiros = () => {
    
    return(
        <div className="section parceiros" id="parceiros">
            <RepeatedTitle text="PARCEIROS" />
            <div className="parceiros-grid">
                <div className="parceiro">
                    <a href="https://www.uc.pt/fctuc/" target="_blank" rel="noreferrer">
                        <img className="UC" src={UC} alt="Logo da Escola Secundária de Rio Tinto" />
                    </a>
                    <a href="mailto:jekacademy@jeknowledge.com">
                        <button className="torna-te">
                            <p className="button-text2"> TORNA-TE PARCEIRO </p>
                        </button>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Parceiros;