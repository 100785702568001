import "./Oradores.css";
import CardOrador from "../CardOrador";
import RepeatedTitle from "../RepeatedTitle";
import Miguel from "../../assets/Oradores/Miguel.png";
import Rita from "../../assets/Oradores/Rita.png";
import Ulisses from "../../assets/Oradores/Ulisses.png";
import Bastos from "../../assets/Oradores/Bastos.png";
import Surpresa from "../../assets/Oradores/Surpresa.png";

const Oradores = () => {
    
    return(
        <div className="section oradores">
            <RepeatedTitle text="ORADORES" />
            <div className="oradores-grid">
            <CardOrador props={{img:Rita, nome:"RITA MATOS", descricao:"PRESIDENTE, JEKNOWLEDGE"}} />
                <CardOrador props={{img: Miguel, nome: "MIGUEL SANTANA", descricao:"ALUMNI, JEKNOWLEDGE"}} />
                <CardOrador props={{img:Bastos, nome:"VASCO BASTOS", descricao:"SENIOR DESIGNER, JEKNOWLEDGE"}} />
                <CardOrador props={{img:Ulisses, nome:"ULISSES RODRIGUES", descricao:"JUNIOR DEVELOPER, JEKNOWLEDGE"}} />
                <CardOrador props={{img:Surpresa}} />
                <CardOrador props={{img:Surpresa}} />
                <CardOrador props={{img:Surpresa}} />
                <CardOrador props={{img:Surpresa}} />
                <CardOrador props={{img:Surpresa}} />
                
            </div>
        </div>
    )
}

export default Oradores;
