import "./Navbar.css"
import { useEffect, useState } from "react";
import Logo from "../../assets/Shapes/logo.png"
import { useMediaQuery } from 'react-responsive'

const Navbar = () => {

  const navLinks = [
    {navLinkId: 'Home', scrollToId: 'home'},
    {navLinkId: 'Sobre', scrollToId: 'sobrenos'},
    {navLinkId: 'Horário', scrollToId: 'horario'},
    {navLinkId: 'FAQ', scrollToId: 'faq'},
    {navLinkId: 'Parceiros', scrollToId: 'parceiros'},
    {navLinkId: 'Preços', scrollToId: 'precos'} 
  ]
  const [open, setOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleOpen = () => {
    setOpen(!open);
  }


  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    const navbarItems = document.querySelectorAll('.navbar-item');
    const header = document.querySelector('.header');
    const home = document.querySelector('#home');
    const homeHeight = home.offsetHeight;
    const navbar = document.querySelector('.navbar');
    const headerHeight = header.offsetHeight;
    
    let navbarHeight = header.offsetHeight;;
    if (isMobile && open) {
      navbarHeight-=navbar.offsetHeight+15;
    }

    navbarItems.forEach(function(link) {
        link.addEventListener('click', function(event) {
        event.preventDefault();

        const targetId = this.getAttribute('href');
        const targetElement = document.querySelector(targetId);

        // Calculate the offset position, subtracting the navbar height
        const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = targetPosition - navbarHeight;
        if (targetId === '#home') {
          window.scrollTo({
            top: targetPosition,
            behavior: 'smooth'
        });
        } else {
          window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth'
          });
        }

        navbarItems.forEach(function(navbarItems) {
          navbarItems.classList.remove('active');
        });
          this.classList.add('active');
        });
    });

   
  }, [open]);
  /* window.addEventListener('scroll', function() {
      const scrollPosition = window.pageYOffset;

      if (scrollPosition >= homeHeight - headerHeight) {
        // Add 'background' class when scroll position is beyond homeHeight - headerHeight
        header.classList.add('background');
        if (!isMobile) {
          navbar.classList.remove('background');
        }
      } else if(scrollPosition < homeHeight - headerHeight){
        if (isMobile && !open) {
          // Add 'background' class when scroll position is beyond the window's viewport height and header is closed
          header.classList.remove('background');
        }
        if (!isMobile) {
          header.classList.remove('background');
          navbar.classList.add('background');
        }
      }
    });
    if (isMobile && open) {
      // Always add 'background' class to the header when it is open
      header.classList.add('background');
    } */

    //use effect start that triggers everytime you scroll
    useEffect(() => {
      const navbarItems = document.querySelectorAll('.navbar-item');
      const header = document.querySelector('.header');
      const home = document.querySelector('#home');
      const homeHeight = home.offsetHeight;
      const navbar = document.querySelector('.navbar');
      const headerHeight = header.offsetHeight;

      let navbarHeight = header.offsetHeight;;
      if (isMobile && open) {
        navbarHeight-=navbar.offsetHeight+15;
      }

      if(!isMobile){
        if (scrollPosition >= homeHeight - headerHeight) {
          // Add 'background' class when scroll position is beyond homeHeight - headerHeight
          header.classList.add('background');
          navbar.classList.remove('background');
        } else if(scrollPosition < homeHeight - headerHeight){
          header.classList.remove('background');
          navbar.classList.add('background');
        }
      } else{
        if (scrollPosition >= homeHeight - headerHeight) {
          // Add 'background' class when scroll position is beyond homeHeight - headerHeight
          header.classList.add('background');
        } else if(scrollPosition < homeHeight - headerHeight){
          if (!open) {
            // Add 'background' class when scroll position is beyond the window's viewport height and header is closed
            header.classList.remove('background');
          } else {
            header.classList.add('background');
          }
        }
      }

    }, [scrollPosition, open]);
    //use effect end
    window.addEventListener('scroll', function() {
      setScrollPosition(window.pageYOffset);
    });



  return (
    !isMobile ? (
    <header className="header">
        <img src={Logo} alt="Logo jeKAcademy"/>
        <nav className="navbar">
          {navLinks.map((navLink) => (
            <a href={`#${navLink.scrollToId}`} className="navbar-item" key={navLink.scrollToId}>{navLink.navLinkId}</a>
          ))}
        </nav>
    </header>
    ) : (
    <div className="header">
      <header className="header-mobile">
          <img src={Logo} alt="Logo jeKAcademy"/>
          <div className="hamburger" onClick={handleOpen}>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
      </header>
      {open && (
        <nav className="navbar">
          {navLinks.map((navLink) => (
            <a href={`#${navLink.scrollToId}`} className="navbar-item" key={navLink.scrollToId}>{navLink.navLinkId}</a>
          ))}
        </nav>
      )}
    </div>
    )
  );
}

export default Navbar;