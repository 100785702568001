import "./CardOrador.css"
//import shadow from "../../assets/shadow.png"
//import shadow from "../../assets/Shapes/Shadow.svg"

const CardOrador = ({props}) => {
    //funtion to get the shape that will be used as background

    return(
        <div className="speaker">
            <div className="speaker-imgs">
                <img src={props.img} alt={props.nome} className="speaker-img"/>
            </div>
            
            <div className="speaker-text">
                <h1>{props.nome}</h1>
                <p>{props.descricao}</p>
            </div>
        </div>
    )
}

export default CardOrador;