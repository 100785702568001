import "./Home.css"
import img from "../../assets/Shapes/home-img.svg"


const Home = () => {
    return (
        <div className="home" id="home">
            <div className="home-text">
                <div className="home-title titulo">
                    <h1 className="home-title1 white">BEM VINDO AO</h1>
                </div>
                <div className="home-title">
                    <h1 className="home-title2 black">JEKNOWLEDGE<br></br>ACADEMY</h1>
                </div>
                <div className="home-title">
                    <h1 className="home-title3 white">JEKNOWLEDGE<br></br>ACADEMY</h1>
                </div>
                <div className="home-title">
                    <h1 className="home-title3 white">JEKNOWLEDGE<br></br>ACADEMY</h1>
                </div>
                <div className="home-title">
                    <h1 className="home-title3 white">JEKNOWLEDGE<br></br>ACADEMY</h1>
                </div>
            </div>
            <img src={img} alt="Imagem do boneco" className="home-img"/>
        </div>
    );
}

export default Home;