import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import SobreNos from './components/SobreNos';
import Informacao from './components/Informacao';
import Horario from './components/Horario';
import Oradores from './components/Oradores';
import FAQ from './components/FAQ';
import Parceiros from './components/Parceiros';
import Bilhetes from './components/Bilhetes';



function App() {
  return (
    <div className="App">
        <Navbar />
        <Home />
        <SobreNos />
        <Informacao />
        <Horario />
        <Oradores />
        <FAQ />
        <Parceiros />
        <Bilhetes />
    </div>
  );
}

export default App;
