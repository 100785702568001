import "./Bilhetes.css"
import Titulos from "../Titulos"
import RepeatedTitle from "../RepeatedTitle"

const Bilhetes = () => {
    return(
        <div className="section bilhetes" id="precos">
            <RepeatedTitle text="BILHETES" />
            <div className="bilhetes-grid">
                <div className="bilhete">
                    <Titulos props={{text:"15€", color:"black"}} />
                    <p>BILHETE NORMAL <br/> (Inclui acesso a todas as atividades) </p>
                </div>
            </div>
        </div>
    )
}

export default Bilhetes;
