import "./FAQ.css"
import Pergunta from "../Pergunta"
import RepeatedTitle from "../RepeatedTitle"

const FAQ = () => {
    
    return(
        <div className="section perguntas" id='faq'>
            <RepeatedTitle text="PERGUNTAS" />
            <div className="perguntas-grid">
                <Pergunta props={{pergunta:"Para quem?", resposta:"Para todos os alunos do 10º ao 12º ano com vontade de aprender e de se desafiar."}} />
                <Pergunta props={{pergunta:"Preciso de ter conhecimentos de programação ou robótica?", resposta:"Não precisas de qualquer conhecimento de programação ou robótica, só precisamos que tragas boa disposição e interesse em aprender."}} />
                <Pergunta props={{pergunta:"Como participo?", resposta:"Para participares basta preencheres o formulário de inscrição e seguires os passos de pagamento indicados. No formulário de inscrição tens todas as informações de pagamento necessárias."}} />
                <Pergunta props={{pergunta:"Como faço o pagamento?", resposta:"Por transferência bancária! No formulário de inscrição tens toda a informação que precisas."}} />
                <Pergunta props={{pergunta:"E o ambiente?", resposta:"Entra em contacto com um ambiente empresarial, aprende connosco e faz-nos aprender contigo também. Não penses que um ambiente empresarial é sinónimo de aborrecimento. Vem e comprova que pode ser exatamente o contrário."}} />
                <Pergunta props={{pergunta:"Ainda não estás convencido?", resposta:"O staff do evento é formado por alunos da FCTUC das mais diversas áreas e é uma forma de ficares a conhecer um pouco do que se faz em cada curso. Estamos prontos para qualquer dúvida que tenhas!"}} />
                <Pergunta props={{pergunta:"Preciso de computador?", resposta:"Sim. O teu computador vai ser a tua ferramenta principal para estes dias espetaculares."}} />
                <Pergunta props={{pergunta:"E se tiver dúvidas?", resposta:"Podes contactar-nos pelo Instagram!"}} />
            </div>
        </div>
    )
}

export default FAQ;