import "./Informacao.css"
//import all the images from the folder Icons which is inside assets    
import Calendar from "../../assets/Icons/Calendar.svg";
import Food from "../../assets/Icons/Food.svg";
import People from "../../assets/Icons/People.svg";
import Location from "../../assets/Icons/Location.svg";
import Clock from "../../assets/Icons/Clock.svg";
import RepeatedTitle from '../RepeatedTitle';

const Informacao = () => {
    return(
        <div className="section informacao">
            <RepeatedTitle text="WORKSHOPS" />
            <div className="informacao-flex">
                <div className="informacao-item">
                    <img src={Location} alt="Localização do evento"/>
                    <h2 className="informacao-item-titulo">DEP. ENG. INFORMÁTICA <br/> DEP. ENG. FÍSICA </h2>
                </div>
                <div className="informacao-item">
                    <img src={Clock} alt="Duração dos workshops evento"/>
                    <h2 className="informacao-item-titulo">2 A 4 DE JULHO</h2>
                </div>
                <div className="informacao-item" alt="Numero de vagas dos workshops">
                    <img src={People} alt={"pessoa"}/>
                    <h2 className="informacao-item-titulo">LIMITE DE 35 PARTICIPANTES<br/> (POR WORKSHOP) </h2>
                </div>
                <div className="informacao-item" alt="Comida">
                    <img src={Food} alt={"comida"}/>
                    <h2 className="informacao-item-titulo">COFFEE BREAKS</h2>
                </div>
            </div>
            <RepeatedTitle text="TALKS" />
            <div className="informacao-flex" >
                <div className="informacao-item">
                    <img src={Calendar} alt="Duração das talks evento"/>
                    <h2 className="informacao-item-titulo">2 A 4 DE JULHO</h2>
                </div>
                <div className="informacao-item">
                    <img src={People} alt="Número de vagas das talks"/>
                    <h2 className="informacao-item-titulo">SEM LIMITE DE PARTICIPANTES</h2>
                </div>
            </div>
        </div>
    )
}

export default Informacao;

